import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GlobalContactForm } from '@phx-husky/global-contact-form';

import ContactsList from 'components/ContactsList';
import Layout from 'components/Layout';

import { gtmService } from 'services/gtmService';

import 'styles/main.scss';
import './ContactUsPage.scss';

interface IContactUsPageData extends PageContent.IDefaultLayoutProps {
  contactUs: ContactUs.Page;
}
const ContactsPage: FC<{ data: IContactUsPageData }> = ({
  data: {
    contactUs: {
      descriptionPhone,
      descriptionAddress,
      description,
      phoneNumber,
      address,
      title,
      seoMetaTitle,
      seoMetaKeywords,
      seoMetaDescription,
      seoCanonicalUrl,
      seoExternalHreflangs,
      seoImage,
      link,
      contactFormLink,
      contactFormTitle,
    },
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const contactsData = [
    {
      title: descriptionPhone,
      icon: 'phone_icon',
      text: `<a href="tel:${phoneNumber}">${phoneNumber}</a>`,
    },
    {
      title: descriptionAddress,
      icon: 'pin_icon',
      text: address,
    },
  ];

  useEffect(() => {
    function iframeMessageHandler(e: MessageEvent) {
      if (e.data.message === gtmService.iframeMessageFormSubmitted) {
        gtmService.emitGenerateLead(gtmService.formNames.contactUs);
      }
    }

    window.addEventListener('message', iframeMessageHandler);

    return () => window.removeEventListener('message', iframeMessageHandler);
  }, []);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaKeywords,
        seoMetaDescription,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      <section className="dt-contacts">
        {phoneNumber || address || title || description ? (
          <Container fluid>
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                {title ? <h1 className="dt-contacts__title">{title}</h1> : null}
                {description ? (
                  <div
                    className="dt-contacts__text"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                ) : null}
                {phoneNumber || address ? <hr /> : null}
                {phoneNumber || address ? <ContactsList data={contactsData} /> : null}
              </Col>
            </Row>
          </Container>
        ) : null}
        {contactFormLink ? (
          <div className="dt-contacts__form">
            <GlobalContactForm
              src={contactFormLink}
              id="contact-form"
              title={contactFormTitle}
              className="contact-form"
            />
          </div>
        ) : null}
      </section>
    </Layout>
  );
};

export default ContactsPage;

export const query = graphql`
  query ContactUsPageQuery($lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    contactUs(lang: { eq: $lang }) {
      link
      title
      descriptionPhone
      descriptionAddress
      description
      phoneNumber
      address
      contactFormLink
      contactFormTitle
      seoMetaTitle
      seoMetaKeywords
      seoMetaDescription
      seoImage
      seoExternalHreflangs {
        key
        value
      }
    }
  }
`;
